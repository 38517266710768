<template>
    <div class="mx-auto  px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center bg-gradient-to-r from-purple-100 lg:pt-32 select-none">
        <h1 class="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-7xl">
        Complaint 
        <span class="relative whitespace-nowrap text-purple-500">
          <svg
            aria-hidden="true"
            viewBox="0 0 418 42"
            class="absolute top-2/3 left-0 h-[0.58em] w-full fill-purple-300/70"
          >
            <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
          </svg>
          <span class="relative">system</span>
        </span>
        for your business.
      </h1>
      <p class="mx-auto mt-6 max-w-2xl text-lg tracking-tight text-slate-700">
          If you’re looking for something to increase efficiency of your business, you are in the right place. Our complaint system provides a complete handling of the complaints process. Focus on effectiveness in customer service!
      </p>
      <div class="mt-10 flex justify-center gap-x-6">
        <a @click="tagClickOnCallToAction" class="group inline-flex items-center justify-center focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-900 hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-purple-500 to-purple-600 hover:opacity-80 py-3 px-5 md:py-3 md:text-base md:px-5 transition-all duration-400 cursor-pointer select-none" href="https://app.complaia.com/selectplan">Start 7-days free trial</a>
      </div>
      <div class="h-60">
      </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    components: {
    },
    data() {
        return {
        }
    },
    methods: {
      tagClickOnCallToAction()
      {
        if(window.dataLayer !== undefined)
        {
          if(window.dataLayer !== null)
          {
              window.dataLayer.push({
                event: "clickedCTAComplaiaCom",
                goingTo: "Select plan"
              });
          }
        }
      }
    },
    async mounted()
    {
      if(window.dataLayer !== undefined)
      {
        if(window.dataLayer !== null)
        {

          window.dataLayer.push({
            event: "visitedComplaiaCom",
            lang: window.navigator.userLanguage || window.navigator.language,
            system: window.navigator.userAgent,
            knownLanguages: window.navigator.languages,
            comingFrom: window.document.referrer
          });


          setTimeout(() => {
            window.dataLayer.push({
              event: "reached10SecondsComplaiaCom",
              reached: true,
              info: "10 seconds"
            });
          },10000);

          setTimeout(() => {
            window.dataLayer.push({
              event: "reached30SecondsComplaiaCom",
              reached: true,
              info: "30 seconds"
            });
          },30000);
        }
      }
      
    }
}
</script>

<style>

</style>