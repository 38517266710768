<template>

  <!-- first features -->

<TabGroup id="features" as="div" class="hidden lg:mt-20 lg:block select-none w-full ">
  <TabPanels class="relative mt-20 overflow-hidden rounded-md bg-gradient-to-r  from-slate-600 to-slate-900  py-8 ">
    <carousel :items-to-show="1" autoplay="4000" wrapAround pauseAutoplayOnHover v-model="activeFirstItem" class="-mx-5 flex">
      <slide v-for="(feature, index) in featuresOne" :key="index" class="px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none">
          <div :class="['overflow-hidden w-5/6 rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10', activeFirstItem === index ? '' : 'opacity-50']">
            <img class="w-full" :src="feature.image" :alt="feature.alt" >
          </div>
      </slide>
    </carousel>
    <div class="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10"></div>
  </TabPanels>
  <TabList as="div" class="grid grid-cols-3 gap-x-8 mt-10" role="tablist" aria-orientation="horizontal">
    <div class="relative" v-for="(feature, index) in featuresOne" :key="index" @click="selectItem(index)">
      <div :class="[activeFirstItem === index ? 'opacity-100' : 'opacity-75 hover:opacity-100']">
        <div :class="['w-10 rounded-lg', activeFirstItem === index ? 'bg-indigo-500' : 'bg-slate-500']">
          <!-- <img class="h-10 w-10 p-1" :src="feature.icon" alt=""> -->
        </div>
        <h3 :class="['mt-6 text-sm font-medium', activeFirstItem === index ? 'text-purple-600' : 'text-slate-600']">
          {{ feature.name }}
        </h3>
        <p class="mt-2 text-sm  text-slate-900">
          {{ feature.summary }}
        </p>
        <p class="mt-4 text-sm text-slate-600">{{ feature.description }}</p>
        <Tab class="[&:not(:focus-visible)]:focus:outline-none">
          <span class="absolute inset-0" />
        </Tab>
      </div>
    </div>
  </TabList>
</TabGroup>

  <!-- second features -->

<TabGroup as="div" class="hidden lg:mt-20 lg:block select-none">
  <TabPanels class="relative mt-20 overflow-hidden rounded-md bg-gradient-to-r  from-slate-600 to-slate-900  py-8 ">
    <carousel :items-to-show="1" autoplay="4000" wrapAround pauseAutoplayOnHover v-model="activeSecondItem" class="-mx-5 flex">
      <slide v-for="(feature, index) in featuresTwo" :key="index" class="px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none">
          <div :class="['overflow-hidden w-5/6 rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10', activeSecondItem === index ? '' : 'opacity-50']">
            <img class="w-full" :src="feature.image" :alt="feature.alt" >
          </div>
      </slide>
    </carousel>
    <div class="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10"></div>
  </TabPanels>
  <TabList as="div" class="grid grid-cols-3 gap-x-8 mt-10" role="tablist" aria-orientation="horizontal">
    <div class="relative" v-for="(feature, index) in featuresTwo" :key="index" @click="selectSecondItem(index)">
      <div :class="[activeSecondItem === index ? 'opacity-100' : 'opacity-75 hover:opacity-100']">
        <div :class="['w-10 rounded-lg', activeSecondItem === index ? 'bg-indigo-500' : 'bg-slate-500']">
          <!-- <img class="h-10 w-10 p-1" :src="feature.icon" alt=""> -->
        </div>
        <h3 :class="['mt-6 text-sm font-medium', activeSecondItem === index ? 'text-purple-600' : 'text-slate-600']">
          {{ feature.name }}
        </h3>
        <p class="mt-2 text-sm text-slate-900">
          {{ feature.summary }}
        </p>
        <p class="mt-4 text-sm text-slate-600">{{ feature.description }}</p>
        <Tab class="[&:not(:focus-visible)]:focus:outline-none">
          <span class="absolute inset-0" />
        </Tab>
      </div>
    </div>
  </TabList>
</TabGroup>

  <!-- third features -->

<TabGroup as="div" class="hidden lg:mt-20 lg:block select-none">
  <TabPanels class="relative mt-20 overflow-hidden rounded-md bg-gradient-to-r  from-slate-600 to-slate-900  py-8 ">
    <carousel :items-to-show="1" autoplay="4000" wrapAround pauseAutoplayOnHover v-model="activeThirdItem" class="-mx-5 flex">
      <slide v-for="(feature, index) in featuresThree" :key="index" class="px-5 transition duration-500 ease-in-out [&:not(:focus-visible)]:focus:outline-none">
          <div :class="['overflow-hidden w-5/6 rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10', activeThirdItem === index ? '' : 'opacity-50']">
            <img class="w-full" :src="feature.image" :alt="feature.alt" >
          </div>
      </slide>
    </carousel>
    <div class="pointer-events-none absolute inset-0 rounded-4xl ring-1 ring-inset ring-slate-900/10"></div>
  </TabPanels>
  <TabList as="div" class="grid grid-cols-3 gap-x-8 mt-10" role="tablist" aria-orientation="horizontal">
    <div class="relative" v-for="(feature, index) in featuresThree" :key="index" @click="selectThirdItem(index)">
      <div :class="[activeThirdItem === index ? 'opacity-100' : 'opacity-75 hover:opacity-100']">
        <div :class="['w-10 rounded-lg', activeThirdItem === index ? 'bg-indigo-500' : 'bg-slate-500']">
          <!-- <img class="h-10 w-10 p-1" :src="feature.icon" alt=""> -->
        </div>
        <h3 :class="['mt-6 text-sm font-medium', activeThirdItem === index ? 'text-purple-600' : 'text-slate-600']">
          {{ feature.name }}
        </h3>
        <p class="mt-2 text-sm text-slate-900">
          {{ feature.summary }}
        </p>
        <p class="mt-4 text-sm text-slate-600">{{ feature.description }}</p>
        <Tab class="[&:not(:focus-visible)]:focus:outline-none">
          <span class="absolute inset-0" />
        </Tab>
      </div>
    </div>
  </TabList>
</TabGroup>

</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

export default {
    components: {
         TabGroup, TabList, Tab, TabPanels, TabPanel, Carousel, Slide, Pagination, Navigation
    },
    methods: {
      selectItem(index) {
        this.activeFirstItem = index;
        this.isActive = !this.isActive
      },
      selectSecondItem(index) {
        this.activeSecondItem = index;
        this.isActive = !this.isActive
      },
      selectThirdItem(index) {
        this.activeThirdItem = index;
        this.isActive = !this.isActive
      }
    },
  data() {
    return {
      activeFirstItem: 0,
      activeSecondItem: 0,
      activeThirdItem: 0,
      featuresOne: [
        {
        name: 'Automated process',
        summary: 'Allow the customer to fill out the complaint form themselves. Simply insert your unique Complaia link into your online store. Simplify your complaint flow now!',
        image: "https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/landingPage%2F1.png?alt=media&token=7bd204ad-fd24-4307-bfe2-70378cd9e966",
        icon: require('../assets/handle-with-care.svg'),
        active: false,
        alt: "resolving customer issues and customer complaint handling"
        },
        {
        name: 'Cloud based',
        summary: 'Resolve complaints from anywhere on earth at any time. Collaborate with your team wherever you want. Work efficiently on your smartphone as well as your desktop device!',
        image: "https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/landingPage%2F6.png?alt=media&token=5ad06468-2d81-45c9-b557-9aa3f52a673d",
        icon: require('../assets/live-chat.svg'),
        active: false,
        alt: "customer complain and customer complaint service"
        },
                {
        name: 'Notifications',
        summary: 'Send automatically or manually personalized information on complaints. Let your customers stay up to date!',
        image: "https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/landingPage%2F3.png?alt=media&token=1cee0f36-9195-467b-97cf-f3be992d64cb",
        icon: require('../assets/notification.svg'),
        active: false,
        alt: "complaints reviews and customer complaint management system"
        },
      ],
      featuresTwo: [
        {
        name: 'Branded online form',
        summary: 'Customize the look of your form with the built-in wizard. Forget about ugly forms. Make your brand stand out from the competition!',
        image: "https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/landingPage%2F4.png?alt=media&token=bd554bdd-fcbd-4212-a5a2-9302bc8858fd",
        icon: require('../assets/customize.svg'),
        alt: "complaint tracking system and e complaint system"
        },
                {
        name: 'Always relevant informations',
        summary: `Set up the fields in the form to get relevant information from your company's point of view. Once we have the customer's information, we will match the claimed products and supervise the process for you.`,
        image: "https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/landingPage%2F9.png?alt=media&token=2f22d7a7-1479-465e-b79c-e55f0dacddc0",
        icon: require('../assets/form.svg'),
        alt: "enterprise complaint system and complaint tracking system"
        },
                {
        name: 'Easy to set up',
        summary: 'Easily import products from sales channels using the CSV integrator to start using the application in a matter of moments.',
        image: "https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/landingPage%2F5.png?alt=media&token=bd38aad0-da7e-43e3-a2ed-47ed4a067070",
        icon: require('../assets/import.svg'),
        alt: "tracking customer complaints and complaint software"
        },
      ],
      featuresThree: [
        {
        name: 'Calendar',
        summary: 'Keep up to date with what needs to be done on any given day. Plan claim tasks for your team.',
        image: "https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/landingPage%2F8.png?alt=media&token=060c568d-c0b5-4ef1-b13f-62f3903b6ba4",
        icon: require('../assets/calendar.svg'),
        alt: "complaint management solution and online complaint system"
        },
        {
        name: 'Analytics',
        summary: 'A detailed analytics module will provide information on what items cause the most problems and where the most money is lost in your company.',
        image: "https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/landingPage%2F7.png?alt=media&token=b8e1f1ee-e79d-494c-8093-e1428a27a356",
        icon: require('../assets/accessibility.svg'),
        alt: "customer support manager and complaint managment"
        },
        {
        name: 'Chat',
        summary: 'Chat right from the app. Stay in touch with your customers by providing an amazing customer experience.',
        image: "https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/landingPage%2F2.png?alt=media&token=8affefd4-3a5b-45e5-b897-06e6e39fcfa2",
        icon: require('../assets/chat.svg'),
        alt: "software customer support and complaint system"
        }
      ],
    }
  }
}
</script>

<style>
.active {
  color: red;
}
</style>
