<template>
<section id="panels" aria-label="Features" class="relative overflow-hidden bg-gradient-to-r from-indigo-400 to-purple-500 pt-20 pb-28 sm:py-32">
  <div class="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="max-w-2xl md:mx-auto md:text-center xl:max-w-none">
      <h2 class="text-3xl tracking-tight text-white sm:text-4xl md:text-5xl select-none">
        Meet a new way to effectively resolve complaints
      </h2>
    </div>
    <TabGroup as="div" class="mt-16 grid grid-cols-1 items-center gap-y-2 pt-10 sm:gap-y-6 md:mt-20 lg:grid-cols-12 lg:pt-0 select-none">
      <div class="-mx-4 flex overflow-x-auto pb-4 sm:mx-0 sm:overflow-visible sm:pb-0 lg:col-span-5">
        <TabList class="relative z-10 flex gap-x-4 whitespace-nowrap px-4 sm:mx-auto sm:px-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1 lg:whitespace-normal">
          <div @click.prevent="selectItem(index)" v-for="(feature, index) in features" :key="index" :class="['group relative rounded-full py-1 px-4 lg:rounded-r-none lg:rounded-l-xl lg:p-6', activeItem === index ? 'bg-white lg:bg-white/10 lg:ring-1 lg:ring-inset lg:ring-white/10' : 'hover:bg-white/10 lg:hover:bg-white/5']">
          <h3>
            <Tab :class="['text-lg [&:not(:focus-visible)]:focus:outline-none focus:outline-none focus:ring-0', activeItem === index ? 'text-indigo-600 lg:text-white' : 'text-blue-100 hover:text-white lg:text-white']">
              <span class="absolute inset-0 rounded-full lg:rounded-r-none lg:rounded-l-xl" />
                {{ feature.title }}
            </Tab>
          </h3>
          <p :class="['mt-2 hidden text-sm lg:block', activeItem === index ? 'text-white' : 'text-blue-100/50 group-hover:text-white']">
            {{ feature.description }}
          </p>
          </div>
        </TabList>
      </div>
      <TabPanels class="lg:col-span-7">
        <TabPanel v-for="feature in features" :key="feature.title">
          <div class="relative sm:px-6 lg:hidden">
            <div class="absolute -inset-x-4 top-[-6.5rem] bottom-[-4.25rem] bg-white/10 ring-1 ring-inset ring-white/10 sm:inset-x-0 sm:rounded-t-xl"></div>
            <p class="relative mx-auto max-w-2xl text-base text-white sm:text-center">
              {{ feature.description }}
            </p>
          </div>
          <div class="mt-10 w-[45rem] overflow-hidden rounded-xl bg-slate-50 shadow-xl shadow-blue-900/20 sm:w-auto lg:mt-0 lg:w-[67.8125rem]">
            <img class="w-full" :src="feature.image" alt="complaint form, how to handle complaints from customers" sizes="(min-width: 1024px) 67.8125rem, (min-width: 640px) 100vw, 45rem">
          </div>
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>
</section>

</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

export default {
    components: {
        TabGroup, TabList, Tab, TabPanels, TabPanel
    },
    methods: {
      selectItem(index) {
        this.activeItem = index;
      }
    },
    data() {
        return {
            activeItem: 0,
            features: [
                {
                    title: 'Complaint Manager',
                    description:
                    "Manage all complaints in one place. Configure and customize the system to suit your needs.",
                    image: require("@/assets/images/screenshots/complaintlist.png"),
                },
                {
                    title: 'Customer Portal',
                    description:
                    "Allow the customer to self-register the complaint and view the status of the complaint via a simple, individual customer panel. Reduce the number of unnecessary questions about the complaints.",
                    image: require("@/assets/images/screenshots/customerpanel.png"),
                },
            ],
        }
    },
    
}
</script>

<style>

</style>