<template>
<header :class="{ 'scrolled': !view.atTopOfPage }"  class="py-5 sticky top-0 z-50 bg-white">
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  <Popover class="relative bg-white" v-slot="{open}">
    <div class="flex justify-between items-center px-4 sm:px-6 md:justify-start md:space-x-10">
      <div>
        <a href="#" class="flex hover:scale-105 transition-all duration-400 ease-in">
          <span class="sr-only">Complaia automated complaints</span>
          <img class="h-10 w-auto sm:h-10" src="@/assets/logomin.png" alt="Complaia - complaint tracking, managing complaints. Customer complaint software service" />
        </a>
      </div>
      <div class="-mr-2 -my-2 md:hidden">
        <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple  -500">
          <span class="sr-only">Open menu</span>
          <MenuIcon class="h-6 w-6" aria-hidden="true" />
        </PopoverButton>
      </div>
      <div class="hidden md:flex-1 md:flex md:items-center md:justify-between">
        <PopoverGroup as="nav" class="flex gap-x-6">
          <a href="#"   v-scroll-to="{
            el: '#panels', 
            duration: 500,
            lazy: true,
            easing: 'ease-in-out',
            force: true,
            cancelable: true,
            x: false,
            y: true
            }" class="rounded-lg py-1 px-2 text-sm font-medium text-slate-700 hover:bg-slate-100 hover:text-slate-900"> Explore </a>
            <a href="#" v-scroll-to="{
                el: '#features',
                duration: 500,
                lazy: true,
                easing: 'ease-in-out',
                offset: -400,
                force: true,
                cancelable: true,
                x: false,
                y: true
            }" class="rounded-lg py-1 px-2 text-sm font-medium text-slate-700 hover:bg-slate-100 hover:text-slate-900"> Features </a>
          <a href="#" v-scroll-to="{
              el: '#pricing',
              duration: 500,
              lazy: true,
              easing: 'ease-in-out',
              offset: 100,
              force: true,
              cancelable: true,
              x: false,
              y: true
          }" class="rounded-lg py-1 px-2 text-sm font-medium text-slate-700 hover:bg-slate-100 hover:text-slate-900"> Pricing </a>

        </PopoverGroup>
        <div class="flex items-center md:ml-12">
          <a href="https://app.complaia.com/" class="rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"> Log In </a>
          <a href="https://app.complaia.com/selectplan" @click="createTagOnGoToSelectPlan" class="ml-8 inline-flex items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-gradient-to-r from-purple-500 to-purple-600 hover:opacity-80 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600"> Register now</a>
        </div>
      </div>
    </div>

    <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
      <PopoverPanel focus class="absolute top-8 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50" v-slot="{close}">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div class="-mr-2">
            <div class="mt-6">
              <nav class="grid gap-6">
                <a v-for="item in solutions" :key="item.name" :href="item.href" class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                  <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white">
                    <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                  </div>
                  <div class="ml-4 text-base font-medium text-gray-900">
                    {{ item.name }}
                  </div>
                </a>
              </nav>
            </div>
          </div>
          
          <div class="py-6 px-5">
            <div class="grid gap-4">
          <a href="#" @click="tabClose(close)" v-scroll-to="{
     el: '#panels',
     duration: 500,
     lazy: true,
     easing: 'ease-in-out',
     force: true,
     cancelable: true,
     x: false,
     y: true
 }" class="rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"> Explore</a>
          <a href="#" @click="tabClose(close)" v-scroll-to="{
     el: '#featuresMobile',
     duration: 500,
     lazy: true,
     easing: 'ease-in-out',
     offset: -400,
     force: true,
     cancelable: true,
     x: false,
     y: true
 }" class="rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"> Features </a>
          <a href="#" @click="tabClose(close)" v-scroll-to="{
     el: '#pricing',
     duration: 500,
     lazy: true,
     easing: 'ease-in-out',
     offset: 100,
     force: true,
     cancelable: true,
     x: false,
     y: true
 }" class="rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"> Pricing </a>
              <a v-for="item in resources" :key="item.name" :href="item.href" class="text-base font-medium text-gray-900 hover:text-gray-700">
                {{ item.name }}
              </a>
            </div>
            <div class="mt-6 border-t">
              <a href="https://app.complaia.com/" class="w-full flex pt-4 border-transparent text-base font-medium text-gray-900"> Log In </a>
              <a href="https://app.complaia.com/selectPlan" @click="createTagOnGoToSelectPlan" class="w-full flex pt-4 border-transparent text-base font-medium text-gray-900"> Register now </a>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  </div>
  </header>
</template>

<script>
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  ChartBarIcon,
  CursorClickIcon,
  DocumentReportIcon,
  MenuIcon,
  RefreshIcon,
  ShieldCheckIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'

export default {
    components: {
        Popover, PopoverButton, PopoverGroup, PopoverPanel, ChartBarIcon, CursorClickIcon, DocumentReportIcon, MenuIcon, RefreshIcon, ShieldCheckIcon,
        ViewGridIcon, XIcon, ChevronDownIcon
    },
    data() {
        return {
          view: {
            atTopOfPage: true,
          }
        }
    },
    beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
      createTagOnGoToSelectPlan()
      {
        if(window.dataLayer !== undefined)
        {
          if(window.dataLayer !== null)
          {
              window.dataLayer.push({
                event: "userGoToSelectPlanComplaiaCom",
                afterVisitOnLandingPage: true
              });
          }
        }
      },
      handleScroll() {
        if(window.pageYOffset>0){
            if(this.view.atTopOfPage) {
              this.view.atTopOfPage = false
            } 
        }
        else
        {
          if(!this.view.atTopOfPage) {
            this.view.atTopOfPage = true
          } 
        }
      },
      tabClose(close)
      {
        setTimeout(()=>{
          close();
        },400)
      }
    }
}
</script>

<style>

.scrolled {
    @apply drop-shadow-xl shadow-black;
    transition: opacity 0.3s ease-in-out;
}

</style>