<template>
  <Header class="shadow-md" />
    <Hero />
      <PrimaryFeatures />
       <SecondaryFeatures class="w-full bg-slate-50" />
      <!-- <CallToAction /> -->
      <Pricing />
    <Footer />
</template>

<script>
import Header from '@/components/Header.vue'
import Hero from '@/components/Hero.vue'
import PrimaryFeatures from '@/components/PrimaryFeatures.vue'
import SecondaryFeatures from '@/components/SecondaryFeatures.vue'
import CallToAction from '@/components/CallToAction.vue'
import Footer from '@/components/Footer.vue'
import Pricing from '@/components/Pricing.vue'

export default {
  components: {
    Header, Hero, PrimaryFeatures, SecondaryFeatures, CallToAction, Footer, Pricing
  },
  data() {
    return {
      date: '',
    }

  }
}
</script>

<style>



</style>