<template>
    <div
      class="flex flex-col rounded-3xl px-6 sm:px-8 order-first bg-blue-600 py-8 lg:order-none lg:py-8">
    <h3 class="mt-5 font-display text-lg text-white">{{ item.name }}</h3>
      <p
        class="mt-2 text-base text-white">
       {{ item.description }}
      </p>
      <p class="order-first font-display text-5xl font-light tracking-tight text-white">
        {{ item.price }}
      </p>
      <ul
        role="list"
        class=" 
          text-white order-last mt-10 flex flex-col gap-y-3 text-sm">
          <li v-for="(feature, index) of plans" :key="index" class="flex">
    <svg aria-hidden="true" class="h-6 w-6 flex-none fill-current stroke-current text-white">
      <path d="M9.307 12.248a.75.75 0 1 0-1.114 1.004l1.114-1.004ZM11 15.25l-.557.502a.75.75 0 0 0 1.15-.043L11 15.25Zm4.844-5.041a.75.75 0 0 0-1.188-.918l1.188.918Zm-7.651 3.043 2.25 2.5 1.114-1.004-2.25-2.5-1.114 1.004Zm3.4 2.457 4.25-5.5-1.187-.918-4.25 5.5 1.188.918Z" strokeWidth={0} />
      <circle cx="12" cy="12" r="8.25" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
            <span class="ml-4">{{feature.name}}</span>
          </li>
      </ul>
      <button href="href" color="white" class="mt-8">
        Get started
      </button>
    </div>
</template>

<script>
export default {
    props: ['plans'],
    data() {
        return {
            plan: this.plans
        }
    }   
}
</script>

<style>

</style>