<template>

<section id="secondary-features" aria-label="Our system provides everything you need to manage your complaints." class="pt-20 pb-14 sm:pb-20 sm:pt-32 lg:pb-32 select-none">
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="mx-auto max-w-2xl md:text-center">
      <h2 class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
        Our app provides everything you need to manage your complaints.
      </h2>
      <p class="mt-4 text-lg tracking-tight text-slate-700">
        Resolve complaints always on time.
      </p>
    </div>
    <FeaturesDesktop />
    <FeaturesMobile />
  </div>
</section>

 <!-- <section id="secondary-features" aria-label="Features for simplifying everyday business tasks" class="pt-20 pb-14 sm:pb-20 sm:pt-32 lg:pb-32" >
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mx-auto max-w-2xl md:text-center">
          <h2 class="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            Our system provides everything you need to manage your complaints.
          </h2>
          <p class="mt-4 text-lg tracking-tight text-slate-700">
            Learn about the system’s basic functions
          </p>
        </div>
        <FeaturesMobile />
        <FeaturesDesktop />
      </div>
    </section> -->
</template>

<script>
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'
import FeaturesDesktop from '@/components/FeaturesDesktop.vue'
import FeaturesMobile from '@/components/FeaturesMobile.vue'

export default {
    components: {
        TabGroup, TabList, Tab, TabPanels, TabPanel, FeaturesDesktop, FeaturesMobile
    },
  data() {
    return {
    }
  }
}
</script>

<style>

</style>