<template>
  <section
      id="get-started-today"
      class="relative overflow-hidden bg-gradient-to-r from-indigo-500 to-purple-500 py-32">
      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative">
        <div class="mx-auto max-w-[36rem] text-center">
          <h2 class="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Get started today
          </h2>
          <p class="mt-4 text-lg tracking-tight text-white">
            1. Register to our website. Registration is completely non-committal.
          </p> 
          <p class="mt-4 text-lg tracking-tight text-white">
            2. Check and test our system during a 7-day trial period. You have the opportunity to customize the settings to make the system even more convenient for you.
          </p>
          <p class="mt-4 text-lg tracking-tight text-white">
            3. After the trial period, you can use the system by paying for system users. You pay $20 when adding your first user. Each additional user costs you just $10. 
          </p>
          <a href="https://app.complaia.com/selectplan" class="group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white mt-10">
            Start 7-days free trial 
          </a>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'CallToAction',
    data() {
        return {

        }
    }
}
</script>

<style>

</style>