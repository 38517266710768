<template>
<div class="-mx-4 mt-5 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden" id="featuresMobile">
  <div v-for="(feature, index) in featuresOne" :key="index">
    <div :class="['mx-auto max-w-2xl', !isActive && 'opacity-100' ]">
      <div :class="['w-10 rounded-lg', isActive ? 'bg-indigo-600' : 'bg-slate-500']">
        <!-- <img class="h-10 w-10 p-1" :src="feature.icon" alt=""> -->
      </div>
      <h3 :class="['mt-6 text-sm font-medium', isActive ? 'text-blue-600' : 'text-purple-600']">
        {{ feature.name }}
      </h3>
      <p class="mt-2 font-display text-lg text-slate-900"> {{ feature.summary }} </p>
      <p class="mt-4 text-sm text-slate-600"> {{ feature.description }} </p>
    </div>
    <div class="relative mt-10 pb-10">
      <div class="absolute -inset-x-4 bottom-0 top-8 bg-gradient-to-r  from-slate-600 to-slate-900 sm:-inset-x-6" />
      <div class="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
        <img class="w-full" :src="feature.image" :alt="feature.alt" sizes="52.75rem">
      </div>
    </div>
  </div>
</div>

<div class="-mx-4 mt-10 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
  <div v-for="(feature, index) in featuresTwo" :key="index">
    <div :class="['mx-auto max-w-2xl', !isActive && 'opacity-100' ]">
      <div :class="['w-10 rounded-lg', isActive ? 'bg-indigo-600' : 'bg-slate-500']">
        <!-- <img class="h-10 w-10 p-1" :src="feature.icon" alt=""> -->
      </div>
      <h3 :class="['mt-6 text-sm font-medium', isActive ? 'text-blue-600' : 'text-purple-600']">
        {{ feature.name }}
      </h3>
      <p class="mt-2 font-display text-lg text-slate-900"> {{ feature.summary }} </p>
      <p class="mt-4 text-sm text-slate-600"> {{ feature.description }} </p>
    </div>
    <div class="relative mt-10 pb-10">
      <div class="absolute -inset-x-4 bottom-0 top-8 bg-gradient-to-r  from-slate-600 to-slate-900 sm:-inset-x-6" />
      <div class="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
        <img class="w-full" :src="feature.image" :alt="feature.alt" sizes="52.75rem">
      </div>
    </div>
  </div>
</div>

<div class="-mx-4 mt-10 flex flex-col gap-y-10 overflow-hidden px-4 sm:-mx-6 sm:px-6 lg:hidden">
  <div v-for="(feature, index) in featuresThree" :key="index">
    <div :class="['mx-auto max-w-2xl', !isActive && 'opacity-100' ]">
      <div :class="['w-10 rounded-lg', isActive ? 'bg-indigo-600' : 'bg-slate-500']">
        <!-- <img class="h-10 w-10 p-1" :src="feature.icon" alt=""> -->
      </div>
      <h3 :class="['mt-6 text-sm font-medium', isActive ? 'text-blue-600' : 'text-purple-600']">
        {{ feature.name }}
      </h3>
      <p class="mt-2 font-display text-lg text-slate-900"> {{ feature.summary }} </p>
      <p class="mt-4 text-sm text-slate-600"> {{ feature.description }} </p>
    </div>
    <div class="relative mt-10 pb-10">
      <div class="absolute -inset-x-4 bottom-0 top-8 bg-gradient-to-r  from-slate-600 to-slate-900 sm:-inset-x-6" />
      <div class="relative mx-auto w-[52.75rem] overflow-hidden rounded-xl bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10">
        <img class="w-full" :src="feature.image" :alt="feature.alt" sizes="52.75rem">
      </div>
    </div>
  </div>
</div>

</template>

<script>

export default {
    data() {
        return {
            isActive: false,
featuresOne: [
        {
        name: 'Automated process',
        summary: 'Allow the customer to fill out the complaint form themselves. Simply insert your unique Complaia link into your online store. Simplify your complaint flow now!',
        image: require('../assets/images/screenshots/complaintlist.png'),
        icon: require('../assets/handle-with-care.svg'),
        active: false,
        alt: "complaints management"
        },
        {
        name: 'Cloud based',
        summary: 'Resolve complaints from anywhere on earth at any time. Collaborate with your team wherever you want. Work efficiently on your smartphone as well as your desktop device!',
        image: require('../assets/images/screenshots/access.png'),
        icon: require('../assets/live-chat.svg'),
        active: false,
        alt: "managing complaints"
        },
                {
        name: 'Notifications',
        summary: 'Send automatically or manually personalized information on complaints. Let your customers stay up to date!',
        image: require('../assets/images/screenshots/inventory.png'),
        icon: require('../assets/notification.svg'),
        active: false,
        alt: "complaints systems"
        },
      ],
      featuresTwo: [
        {
        name: 'Branded online form',
        summary: 'Customize the look of your form with the built-in wizard. Forget about ugly forms. Make your brand stand out from the competition!',
        image: require('../assets/images/screenshots/panelmanager.png'),
        icon: require('../assets/customize.svg'),
        alt: "customer complaint software"
        },
                {
        name: 'Always relevant informations',
        summary: `Set up the fields in the form to get relevant information from your company's point of view. Once we have the customer's information, we will match the claimed products and supervise the process for you.`,
        image: require('../assets/images/screenshots/complaintlink.png'),
        icon: require('../assets/form.svg'),
        alt: "complaint ticket"
        },
                {
        name: 'Easy to set up',
        summary: 'Easily import products from sales channels using the CSV integrator to start using the application in a matter of moments.',
        image: require('../assets/images/screenshots/csv.png'),
        icon: require('../assets/import.svg'),
        alt: "customer complaints website"
        },
      ],
      featuresThree: [
        {
        name: 'Calendar',
        summary: 'Keep up to date with what needs to be done on any given day. Plan claim tasks for your team.',
        image: require('../assets/images/screenshots/calendar.png'),
        icon: require('../assets/calendar.svg'),
        alt: "complaints management process"
        },
        {
        name: 'Analytics',
        summary: 'A detailed analytics module will provide information on what items cause the most problems and where the most money is lost in your company.',
        image: require('../assets/images/screenshots/details.png'),
        icon: require('../assets/accessibility.svg'),
        alt: "handling customer service complaints"
        },
        {
        name: 'Chat',
        summary: 'Chat right from the app. Stay in touch with your customers by providing an amazing customer experience.',
        image: require('../assets/images/screenshots/profit-loss.png'),
        icon: require('../assets/chat.svg'),
        alt: "complaint tracking module, complaint analytics for business"
        }
      ]
        }
    }
}
</script>

<style>

</style>