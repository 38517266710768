<template>
  <router-view/>
</template>

<script>
  export default {
    watch: {
      // Set meta tags
      // $route(to) {
      //   this.setMeta(to.meta);
      // },
    },
    methods: {
      setMeta(meta) {
        // document.title = meta.title;
        // if(meta.title === undefined)
        // {
        //   document.title = 'Complaia.com';
        // }
      }
    }
  }
</script>


<style>
#app {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
</style>
